import {useMemo, useState, useEffect, ReactNode} from 'react';
import {Checkbox, Popover, Select, SelectProps, Typography} from '@mui/material';

import {Search} from '../../../components';
import {usePopover, useSearch} from '../../../hooks';
import {FormattedStatus} from '../../../sections/events/events-table/utils';
import {GetStatusBackground} from '../../../utils/style';

import {StyledFormControl, StyledMenuItem, StyledOutlinedInput, StyledPopoverContent} from './styles';

type StatusTypes = 'active' | 'ended' | 'new' | 'In-Progress' | 'completed' | 'n/a';

export type BasicSearchableSelectProps<OptionT = any> = Omit<SelectProps<OptionT>, 'value' | 'onChange'> & {
  defaultValue: OptionT | null;
  value: OptionT | null;
  placeholder: string;
  onChange: (value: OptionT | null) => void;
  options: OptionT[];
  searchKeys?: string[];
  keyExtractor: (option: OptionT) => string;
  renderOption: (option: OptionT, isSelected: boolean) => ReactNode;
  marginval?: string;
  status: StatusTypes;
};

export const BasicSearchableSelect = ({
  defaultValue,
  value,
  placeholder,
  onChange,
  options,
  keyExtractor,
  renderOption,
  searchKeys,
}: BasicSearchableSelectProps) => {
  const [selected, setSelected] = useState<FormattedStatus | null>(defaultValue);
  const {open, handleOpen, handleClose} = usePopover();
  const {searchValue, onChangeSearchValue, onResetSearchValue} = useSearch();

  const statusOptions = ['New', 'In-Progress', 'Completed'];

  const defaultSearchKeys = searchKeys ? searchKeys : Object.keys(options[0]);

  const onAddSelected = (option: FormattedStatus) => {
    setSelected(option);
    onChange(option);
  };

  const notSelectedOptions = useMemo(
    () =>
      options?.filter(option => {
        const searchFilter = searchValue
          ? defaultSearchKeys.some(key => {
              if (typeof option[key] !== 'string') return true;
              return option[key].toLowerCase().includes(searchValue.toLowerCase());
            })
          : true;

        return searchFilter;
      }),
    [options, searchValue],
  );

  useEffect(() => {
    setSelected(value); // Update to use defaultValue if value is null
  }, [value]);

  return (
    <StyledFormControl sx={{width: '120px'}} background={GetStatusBackground(selected?.status.toLowerCase())}>
      <Select
        id="single-checkbox"
        labelId="single-checkbox-label"
        open={Boolean(open)}
        onOpen={handleOpen}
        sx={{px: '0px', py: '6px'}}
        defaultValue={defaultValue}
        value={selected ? selected : 'N/a'}
        placeholder={placeholder}
        input={<StyledOutlinedInput />}
        renderValue={selected => (
          <Typography variant="bodyBig">
            {statusOptions.includes(selected?.status) ? selected?.status : 'N/A'}
          </Typography>
        )}
      />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}>
        <StyledPopoverContent>
          <Search value={searchValue} onChange={onChangeSearchValue} onReset={onResetSearchValue} />
          {notSelectedOptions?.map(optionItem => {
            const key = keyExtractor(optionItem);

            return (
              <StyledMenuItem
                key={key}
                value={optionItem as string}
                onClick={() => {
                  onAddSelected(optionItem);
                }}>
                <Checkbox checked={selected === optionItem} />
                {renderOption(optionItem, selected === optionItem)}
              </StyledMenuItem>
            );
          })}
        </StyledPopoverContent>
      </Popover>
    </StyledFormControl>
  );
};
