import {useState} from 'react';
import {Typography, useTheme, Box, InputAdornment, Button} from '@mui/material';
import {useAppDispatch} from '../../store';
import {setToggleActionPerformed} from '../../store/reducers/commentsActionSlice';

import {Icon, Avatar, SnackbarAlert} from '../../components';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {getInitials, getCommenterInitials, getFullDateTime} from '../../helpers';
import {userService, eventsService} from '../../api';
import {StyledActions, StyledSendButton, StyledTextField} from './styles';
import {EventCommentProps, Severity} from './types';
import moment from 'moment';

export const EventComments = ({event, onRefetch}: EventCommentProps): JSX.Element => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Severity>('success');
  const [message, setMessage] = useState<string>('');
  const [activeEditCommentId, setActiveEditCommentId] = useState<number>();
  const [activeEditCommentText, setActiveEditCommentText] = useState<string>();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentDateTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

  const {
    data: eventCommentsByThreadId,
    // isLoading: areEventCommentsByThreadIdLoading,
    // error: eventCommentsByThreadIdError,
    refetch: refetchEventCommentsByThreadId,
  } = eventsService.useGetEventCommentsByThreadIdQuery({
    params: {id: event.id},
  });

  const [createEventComment] = eventsService.useCreateEventCommentMutation();
  const [editEventComment] = eventsService.useEditEventCommentMutation();
  const [deleteEventComment] = eventsService.useDeleteEventCommentMutation();

  const [eventComment, setEventComment] = useState<string>('');
  const onChangeEventComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEventComment(e.target.value);
  };

  const onChangeActiveEditEventComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setActiveEditCommentText(e.target.value);
  };

  const userId = localStorage.getItem('userId');
  // @ts-expect-error: need to fix it
  const {data: user} = userService.useGetUserByIdQuery({params: {id: userId}});
  const {firstName, lastName} = user || {};
  const initials = getInitials({firstName, lastName});

  const onCreateEventComment = async () => {
    if (!event.id || !userId) throw new Error('event id or userId are undefined, unhandled error');
    try {
      await createEventComment({
        body: {
          eventNotificationId: event.id,
          commenterUserId: Number(userId),
          text: eventComment,
          creationTimestamp: currentDateTime,
        },
      });

      refetchEventCommentsByThreadId();
      setMessage('Comment added successfully!');
      setSeverity('success');
      setOpenSnackbar(true);
      setEventComment('');
      dispatch(setToggleActionPerformed());
      onRefetch();
    } catch (e) {
      setMessage('Error while adding comment!');
      setSeverity('error');
      setOpenSnackbar(true);
      console.log('ERROR - onCreateEventComment', e);
    }
  };

  const onEditEventComment = async () => {
    if (!event.id || !userId) throw new Error('event id or userId are undefined, unhandled error');

    try {
      await editEventComment({
        body: {
          id: activeEditCommentId,
          text: activeEditCommentText,
          lastEditTimestamp: currentDateTime,
        },
      });
      setMessage('Comment edited successfully!');
      setSeverity('success');
      setOpenSnackbar(true);
      setActiveEditCommentId(undefined);
      setActiveEditCommentText('');
      onRefetch();
    } catch (e) {
      setMessage('Error while editing comment!');
      setSeverity('error');
      setOpenSnackbar(true);
      console.log('ERROR - onEditEventComment', e);
    }
  };

  const onDeleteEventComment = async (commentId: number) => {
    if (!commentId) throw new Error('Comment id is undefined!');
    try {
      await deleteEventComment({commentId});
      setMessage('Comment deleted successfully!');
      setSeverity('success');
      setOpenSnackbar(true);
      refetchEventCommentsByThreadId();
      dispatch(setToggleActionPerformed());
      onRefetch();
    } catch (e) {
      setMessage('Error while deleting comment!');
      setSeverity('error');
      setOpenSnackbar(true);
      console.log('ERROR - onCreateEventComment', e);
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const commentsNumber = eventCommentsByThreadId ? eventCommentsByThreadId.length : 0;
  const hasComments = commentsNumber > 0;
  const isSubmitDisabled = eventComment.length === 0;
  const isEditCommentSubmitDisabled = activeEditCommentText?.length === 0;

  return (
    <>
      {hasComments &&
        eventCommentsByThreadId &&
        eventCommentsByThreadId.map((eventComment, index, array) => {
          const {commenterUserId, commenterFirstName, commenterLastName, creationDateTime, text, id} = eventComment;
          const didSignedInUserComment = commenterUserId?.toString() === userId;
          const commenterInitials = getCommenterInitials({commenterFirstName, commenterLastName});

          return (
            <Box
              key={id}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                borderBottom: index !== array.length - 1 ? '1px solid var(--Basic-100, #EEE)' : 'none',
                marginTop: '30px',
              }}>
              <Box sx={{mr: '8px', display: 'flex', flexDirection: 'column', padding: '1rem'}}>
                <Avatar
                  initials={didSignedInUserComment ? initials : commenterInitials}
                  size="sm"
                  src={null}
                  typographyVariant="uppercaseSmall"
                />
                <Typography sx={{color: theme.palette.basic[500], fontSize: '10px'}}>{commenterFirstName}</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, mt: '7px', width: '93%'}}>
                {activeEditCommentId === id ? (
                  <>
                    <StyledTextField
                      value={activeEditCommentText}
                      onChange={onChangeActiveEditEventComment}
                      placeholder="Edit a comment"
                      multiline
                      variant="standard"
                      minRows={2}
                      maxRows={5}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{position: 'absolute', bottom: '-5px', right: 0}}>
                            <StyledActions>
                              <StyledSendButton
                                type="button"
                                onClick={onEditEventComment}
                                disabled={isEditCommentSubmitDisabled || text === activeEditCommentText}>
                                <Icon icon="add-comment" size="14px" color={theme.palette.common.white} />
                              </StyledSendButton>
                            </StyledActions>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      variant="text"
                      size="small"
                      sx={{padding: '10px', alignSelf: 'flex-start'}}
                      onClick={() => {
                        setActiveEditCommentId(undefined);
                        setActiveEditCommentText('');
                      }}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Typography variant="bodyBig" color={theme.palette.basic[1000]} sx={{fontSize: '11px'}}>
                      {creationDateTime ? getFullDateTime(creationDateTime) : 'No event date available!'}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={() => {
                          setActiveEditCommentId(id);
                          setActiveEditCommentText(text);
                        }}>
                        <EditIcon sx={{mt: '-7.5px', ml: '10px', '&:hover': {backgroundColor: 'transparent'}}} />
                      </IconButton>
                      {id && (
                        <IconButton onClick={() => onDeleteEventComment(id)}>
                          <DeleteIcon sx={{mt: '-7.5px'}} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                )}
                {activeEditCommentId !== id && (
                  <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Typography sx={{color: theme.palette.basic[500], fontSize: '13px', wordWrap: 'break-word'}}>
                      {text}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      <StyledTextField
        value={eventComment}
        onChange={onChangeEventComment}
        placeholder="Add a comment"
        multiline
        variant="standard"
        minRows={2}
        maxRows={5}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{position: 'absolute', bottom: '-5px', right: 0}}>
              <StyledActions>
                <StyledSendButton type="button" onClick={onCreateEventComment} disabled={isSubmitDisabled}>
                  <Icon icon="add-comment" size="16px" color={theme.palette.common.white} />
                </StyledSendButton>
              </StyledActions>
            </InputAdornment>
          ),
        }}
      />
      <SnackbarAlert open={openSnackbar} onClose={handleClose} message={message} severity={severity} />
    </>
  );
};
