import {useState} from 'react';
import {Popover, Typography, useTheme} from '@mui/material';
import {useParams} from 'react-router-dom';

import {Avatar, Icon, Search, CircularProgress} from '../../components';
import {getFullName, getInitials} from '../../helpers';
import {usePopover, useSearch} from '../../hooks';
import {userService, eventsService} from '../../api';
import type {User, EventResponse} from '../../api/service-types';

import {StyledAssignButton, StyledMenuItem, StyledParticipant, StyledPopoverContent} from './styles';

type AssignProps = {
  event: EventResponse;
};

export const Assign = ({event}: AssignProps): JSX.Element => {
  const theme = useTheme();

  const {id} = useParams();
  const hasClinicalTrialId = !!id;

  const {data: events, isFetching} = eventsService.useGetEventsByClinicalTrialIdQuery(
    {params: {id: id!}},
    {skip: !hasClinicalTrialId},
  );

  const hasEvents = !!events;

  const nextEvent = hasEvents
    ? events.data.find(({id, description}) => id === event.id && description === event.description) ?? event
    : event;

  const {open, handleOpen, handleClose} = usePopover<HTMLButtonElement>();
  const [assignedUserId, setAssignedUserId] = useState<User['id'] | null>(nextEvent.assignedUserId ?? null);
  if (!isFetching && hasClinicalTrialId && assignedUserId !== nextEvent.assignedUserId) {
    setAssignedUserId(nextEvent.assignedUserId ?? null);
  }
  const {searchValue, onChangeSearchValue, onResetSearchValue} = useSearch();

  const [updateEvent] = eventsService.useUpdateEventMutation();

  const assignUser = async (nextAssignedUserId: User['id'] | null) => {
    try {
      await updateEvent({
        body: {
          id: nextEvent.id,
          assignedUserId: nextAssignedUserId,
        },
      }).unwrap();

      handleClose();
      setAssignedUserId(nextAssignedUserId);
    } catch (e) {
      handleClose();
      setAssignedUserId(nextAssignedUserId);
      // TODO: add error handler
      console.log('ERROR - assignUser', e);
    }
  };

  const {
    data: users,
    isLoading: areUsersLoading,
    error: usersError,
    refetch: refetchUsers,
  } = userService.useGetUsersQuery();

  const onRefetch = async () => {
    refetchUsers();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areUsersLoading) {
    return <CircularProgress size={40} />;
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (usersError) {
    return (
      <div>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!users) {
    throw new Error('users are undefined, unhandled state');
  }

  const filteredUsers = users.filter(user => {
    const {firstName, lastName} = user;
    const fullName = getFullName({firstName, lastName});

    const searchValueToLowerCase = searchValue.toLowerCase();
    const fullNameToLowerCase = fullName.toLowerCase();

    if (fullNameToLowerCase.includes(searchValueToLowerCase)) {
      return user;
    }

    return null;
  });

  const assignedUser = users.find(user => user.id === assignedUserId);

  return (
    <>
      <StyledAssignButton onClick={handleOpen} open={Boolean(open)}>
        {assignedUser ? (
          // TODO: add src when it'll be on the back-end
          <Avatar
            initials={getInitials({firstName: assignedUser.firstName, lastName: assignedUser.lastName})}
            size="sm"
            typographyVariant="uppercaseSmall"
            src={null}
          />
        ) : (
          <Icon icon="add-user" size="16px" />
        )}
      </StyledAssignButton>
      <Popover
        sx={{zIndex: 9999}}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}>
        <StyledPopoverContent>
          <Search value={searchValue} onChange={onChangeSearchValue} onReset={onResetSearchValue} />

          <div>
            {filteredUsers.map(user => {
              const {id, firstName, lastName} = user;
              const initials = getInitials({firstName, lastName});
              const fullName = getFullName({firstName, lastName});
              const isUserAssigned = id === assignedUser?.id;

              return (
                <StyledMenuItem
                  key={id}
                  onClick={() => {
                    assignUser(isUserAssigned ? null : user.id);
                  }}>
                  <StyledParticipant isSelected={isUserAssigned}>
                    {/* TODO: add src when it'll be on the back-end */}
                    <Avatar initials={initials} size="xs" typographyVariant="uppercaseSmall" src={null} />
                    <Typography
                      variant={isUserAssigned ? 'bodyBigSemibold' : 'bodyBig'}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {fullName}
                    </Typography>
                  </StyledParticipant>
                  {isUserAssigned && <Icon icon="check" size="14px" color={theme.palette.primaryCustom[800]} />}
                </StyledMenuItem>
              );
            })}
          </div>
        </StyledPopoverContent>
      </Popover>
    </>
  );
};
