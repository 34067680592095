import {
  useTheme,
  Typography,
  Box,
  CircularProgress,
  Tabs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  IconButton,
} from '@mui/material';
import {StyledContainer, StyledTablesContainer} from '../events/styles';
import React, {useEffect, useState} from 'react';
import {a11yProps, CustomTabPanel, LowerCaseTab} from '../data-tables';
import {participantsService} from '../../api';
import axios from 'axios';
import {ENDPOINT_TIME_OPTIONS, httpsUrl} from '../../constants';
import {Icon} from '../../components';
import {changeFormateDate} from '../../utils';

export type LogData = {
  id: number;
  participantId: number;
  androidId: string;
  level: string;
  tag: string;
  message: string;
  logUtcInstant: string;
  logParticipantLocalDateTime: string;
};

export type LogResponse = {
  totalCount: number;
  currentStart: number;
  nextStart: number;
  timeframe: string;
  data: LogData[];
};

export const LogsPage = () => {
  const theme = useTheme();

  const [value, setValue] = useState(0); // Active tab index
  const [tabParticipant, setTabParticipant] = useState<number | null>(1); // Selected participant ID
  const [responseData, setResponseData] = useState<LogResponse | null>(null);
  const [timeFrame, setTimeFrame] = useState<string>(ENDPOINT_TIME_OPTIONS.WEEK);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery();

  useEffect(() => {
    if (tabParticipant !== null && tabParticipant !== undefined) {
      setIsLoading(true);
      axios
        .get<LogResponse>(`${httpsUrl}/logging?participant_id=${tabParticipant}&timeframe=${timeFrame}`)
        .then(response => {
          setResponseData(response.data);
        })
        .catch(error => {
          console.error('Error fetching logs:', error);
          setResponseData(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [tabParticipant, timeFrame]);

  // Handle changes
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const selectedParticipant = participants ? participants[newValue] : null;
    if (selectedParticipant && selectedParticipant.id) {
      setTabParticipant(selectedParticipant.id);
    }
  };
  const handleChangeTimeFrame = (event: SelectChangeEvent) => {
    setTimeFrame(event?.target?.value as string);
  };

  const onRefetch = async () => {
    refetchParticipants();
  };

  if (areParticipantsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }
  if (participantsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  return (
    <StyledTablesContainer>
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Logs
      </Typography>
      <StyledContainer>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            {participants &&
              participants.map((participant, index) => (
                <LowerCaseTab key={`logsParticipant${participant.id}`} label={participant.name} {...a11yProps(index)} />
              ))}
          </Tabs>
        </Box>
        {participants &&
          participants.map((participant, index) => (
            <CustomTabPanel key={participant.id} value={value} index={index}>
              <Box sx={{width: 500, marginTop: 2, marginBottom: 4, display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Time Frame</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timeFrame}
                    label="Time Frame"
                    onChange={handleChangeTimeFrame}>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>1 Hour</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.EIGHT_HOURS}>8 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.TEN_HOURS}>10 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.TWELVE_HOURS}>12 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.SIXTEEN_HOURS}>16 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 Days</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>1 Week</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 Weeks</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>1 Month</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Log UTC Instant</TableCell> */}
                      <TableCell>Participant Local Time</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {isLoading ? (
                      // Mostra o indicador de carregamento
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          <CircularProgress size={24} />
                        </TableCell>
                      </TableRow>
                    ) : responseData?.data.length ? (
                      // Mostra os logs quando os dados estão disponíveis
                      responseData.data.map(log => (
                        <TableRow key={log.id}>
                          <TableCell>{changeFormateDate(log.logParticipantLocalDateTime)}</TableCell>
                          <TableCell>{log.message}</TableCell>
                          <TableCell>
                            <Tooltip
                              title={
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                  {[
                                    {label: 'Id', value: log.id},
                                    {label: 'Android Id', value: log.androidId},
                                    {label: 'Participant Id', value: log.participantId},
                                    {label: 'Level', value: log.level},
                                    {label: 'Tag', value: log.tag},
                                    {label: 'UTC Time', value: changeFormateDate(log.logUtcInstant)},
                                  ].map((item, index) => (
                                    <Box key={index} sx={{display: 'flex', gap: 1}}>
                                      <Typography variant="body2" sx={{fontWeight: 800}}>
                                        {item.label}:
                                      </Typography>
                                      <Typography variant="body2">{item.value}</Typography>
                                    </Box>
                                  ))}
                                </Box>
                              }
                              arrow>
                              <div style={{position: 'relative', float: 'right', height: '15px'}}>
                                <IconButton>
                                  <Icon icon="info" size={16} color={theme.palette.basic[500]} />
                                </IconButton>
                              </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      // Mostra uma mensagem de "sem logs" se os dados estão vazios
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No logs available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          ))}
      </StyledContainer>
    </StyledTablesContainer>
  );
};
