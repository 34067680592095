import {useTheme, Typography, Box, Divider, Grid, Tooltip} from '@mui/material';
import {DeviceCard} from '../../../components';
import {GeneralSectionProps} from './types';
import {StyledContainer} from './styles';
import {GraphsWrapper} from '../../../sections/participant/general/GraphsWrapper';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {pollingInterval} from '../../../constants/api';
import {measurementsService, participantsService} from '../../../api/services';
import {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {timeSampConvert} from './utils';

export const GeneralSection = ({generalRef, dynamicData, staticData}: GeneralSectionProps) => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {id, basalProfiles} = staticData;
  const {devices, mealEvents, boluses} = dynamicData;
  const profile = basalProfiles[0] ? basalProfiles[0]?.profile.map(value => value / 12) : []; // get array containing basal profile in U

  const {
    data: latestCgmMeasurements,
    isUninitialized: isLatestCgmMeasurementsUninitialized,
    refetch: refetchLatestCgmMeasurements,
  } = measurementsService.useGetLatestCgmMeasurementsQuery(undefined, {
    pollingInterval,
  });

  const {
    data: dynamicDataLastHours,
    isUninitialized: isDynamicDataLastHoursUnitialized,
    refetch: refetchDynamicDataLastHours,
  } = participantsService.useGetDynamicScreenDataQuery({
    params: {id: `${id}?timeframe=3h`},
  });

  const refetchAllData = useCallback(() => {
    if (!isLatestCgmMeasurementsUninitialized) {
      refetchLatestCgmMeasurements();
    }
    if (!isDynamicDataLastHoursUnitialized) {
      refetchDynamicDataLastHours();
    }
  }, [
    isLatestCgmMeasurementsUninitialized,
    refetchLatestCgmMeasurements,
    isDynamicDataLastHoursUnitialized,
    refetchDynamicDataLastHours,
  ]);

  useEffect(() => {
    const handleInterval = () => {
      refetchAllData();
      console.log('refetch');
    };

    intervalRef.current = setInterval(handleInterval, 300000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refetchAllData]);

  const latestCgmMeasurement =
    latestCgmMeasurements &&
    latestCgmMeasurements.find((measurement: {participantId: number}) => measurement.participantId === id);

  const cgmMeasurementsValue = mmolPerLToMgPerdL(latestCgmMeasurement?.measurement ?? 0, unit);

  const latestLoopMode = latestCgmMeasurement?.loopMode ?? '--';

  const currentTimeInMs = new Date(timeSampConvert(new Date().toISOString(), 'Europe/Zurich')).getTime(); // TODO: make it dynamic (second release)
  const twoHoursAgoInMs = currentTimeInMs - 2 * 60 * 60 * 1000;
  const twoHoursAgo = new Date(twoHoursAgoInMs);

  const LastHoursInsulinTotal = useMemo(() => {
    if (!dynamicDataLastHours) return {total: null, earliestTimepoint: null};

    let total = 0;
    let earliestTimepoint = new Date(timeSampConvert(new Date().toISOString(), 'Europe/Zurich'));
    const timeSlots = new Map(); // create map for time slots
    const slotsCount = 24; // define number of slots (every 5min in 2h)
    const halfSlotDuration = 2.5 * 60 * 1000; // define slot length / 2
    let offset = 0;
    // populate map with corresponding basal profile values
    for (let i = 0; i < slotsCount; i++) {
      // handle edge cases
      if (i === 0) {
        offset = halfSlotDuration;
      } else if (i === slotsCount - 1) {
        offset = -halfSlotDuration;
      }
      const timeSlot = twoHoursAgoInMs + i * 2 * halfSlotDuration + offset; // increment time by 5min for each slot
      const staticIndex = Math.floor(new Date(timeSlot).getHours() % 24); // map to corresponding hour in basal profile
      timeSlots.set(timeSlot, profile[staticIndex] || 0);
    }

    dynamicDataLastHours.boluses.forEach(item => {
      if (!item.eventLocalDateTime) return;

      const eventTime = new Date(item.eventLocalDateTime);
      if (eventTime >= twoHoursAgo) {
        total += item?.mealBolusPassedSafetyNet
          ? item?.mealBolusSafetyNetAmount ?? 0
          : item?.mealBolusComputedAmount ?? 0;
        total += item?.manualBolusPassedSafetyNet
          ? item?.manualBolusSafetyNetAmount ?? 0
          : item?.manualBolusComputedAmount ?? 0;
        total += item?.correctionBolusPassedSafetyNet
          ? item?.correctionBolusSafetyNetAmount ?? 0
          : item?.correctionBolusComputedAmount ?? 0;
      }

      if (eventTime < earliestTimepoint) {
        earliestTimepoint = eventTime;
      }
    });

    dynamicDataLastHours.microboluses.forEach(item => {
      if (!item.eventLocalDateTime) return;

      const eventTime = new Date(item.eventLocalDateTime);
      if (eventTime > twoHoursAgo && eventTime <= twoHoursAgo) {
        // define time window with 2.5 minutes tolerance around event
        const lowerBound = eventTime.getTime() - halfSlotDuration;
        const upperBound = eventTime.getTime() + halfSlotDuration;

        // search for nearest time slot within the range
        timeSlots.forEach((value, key) => {
          if (key > lowerBound && key <= upperBound) {
            timeSlots.set(
              key,
              item?.basalPassedSafetyNet ? item?.basalSafetyNetAmount ?? 0 : item?.basalComputedAmount ?? 0,
            );
          }
        });
      }
    });
    timeSlots.forEach((value, key) => {
      total += value; // add values from microboluses or static data
      if (key < earliestTimepoint.getTime()) {
        earliestTimepoint = new Date(key - halfSlotDuration); // track earliest timepoint - tolerance
      }
    });
    total = total || 0.001; // add tolerance to make zero value visible
    return {total, earliestTimepoint};
  }, [dynamicDataLastHours, twoHoursAgo]);

  const {total, earliestTimepoint} = LastHoursInsulinTotal as {total: number; earliestTimepoint: Date};

  // Temporary solution until endpoint is ready
  const [lastMeal, setLastMeal] = useState(() => {
    if (mealEvents && boluses) {
      const bolus = boluses?.find(bolus => bolus?.eventLocalDateTime === mealEvents[0]?.eventLocalDateTime);
      return {
        timestamp: mealEvents[0]?.eventLocalDateTime ? mealEvents[0].eventLocalDateTime : undefined,
        amount: mealEvents[0]?.carbohydratesEstimate ? mealEvents[0].carbohydratesEstimate : undefined,
        value: bolus?.mealBolusPassedSafetyNet
          ? bolus?.mealBolusSafetyNetAmount ?? undefined
          : bolus?.mealBolusComputedAmount ?? undefined,
      };
    }
    return {timestamp: undefined, amount: undefined, value: undefined};
  });

  useEffect(() => {
    if (dynamicDataLastHours?.mealEvents[0] != null) {
      const bolus = dynamicDataLastHours?.boluses?.find(
        bolus => bolus.eventLocalDateTime === dynamicData?.mealEvents[0]?.eventLocalDateTime,
      );
      setLastMeal(prev => ({
        ...prev,
        timeStamp: dynamicDataLastHours?.mealEvents[0].eventLocalDateTime,
        amount: dynamicDataLastHours?.mealEvents[0].carbohydratesEstimate,
        value: bolus?.mealBolusPassedSafetyNet
          ? bolus?.mealBolusSafetyNetAmount ?? undefined
          : bolus?.mealBolusComputedAmount ?? undefined,
      }));
    }
  }, [dynamicDataLastHours]);

  return (
    <StyledContainer ref={generalRef}>
      <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
        {staticData && (
          <Box sx={{display: 'flex', flexDirection: 'column', mb: '7px'}}>
            <Typography sx={{color: '#9099AA', fontSize: '13px'}}>#{staticData.id}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}>
                {staticData.name}
                <Tooltip title="Test Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserXmark as IconProp}
                    size="lg"
                    color="red"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip>
                {/* <Tooltip title="Real Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserCheck as IconProp}
                    size="lg"
                    color="#059984"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip> */}
              </Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Grid container xs={12} lg={12} xl={9} item spacing={2} mt={0.5} mb={1}>
          {devices[2] && (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <DeviceCard
                cardHeading="Glucose Monitor"
                showReservoirDetails={false}
                showBatteryPercentage={false}
                lastUpdatedTimeTitle="Last update:"
                lastUpdatedTime={latestCgmMeasurement?.eventLocalDateTime}
                showReadingValueAndUnit={true}
                readingValue={cgmMeasurementsValue}
                readingUnit={unit}
                readingTrend={latestCgmMeasurement?.trend}
                tooltipText="Glycemia read by the CGM sensor, current glucose rate of change, and control mode"
                isDeviceCard={true}
                readingMode={latestLoopMode}
                showLoopMode={true}
              />
            </Grid>
          )}
          {devices[1] && LastHoursInsulinTotal && (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <DeviceCard
                cardHeading="Insulin Pump"
                showReservoirDetails={true}
                reservoirPercentage={devices[1].pumpInsulinPercentage}
                showBatteryPercentage={true}
                batteryPercentage={devices[1].batteryPercentage}
                lastUpdatedTimeTitle="Insulin infused in the last 2h:"
                lastUpdatedTime={String(earliestTimepoint)}
                showLastHoursInsulin={true}
                readingValue={total}
                isDeviceCard={true}
                tooltipText="Sum of basal, automatic correction boluses, meal boluses, and manual correction boluses in the past 2 hours"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="Last communicated meal"
              showReservoirDetails={false}
              lastUpdatedTimeTitle="Corresponding meal bolus "
              readingValue={lastMeal.value}
              secondReadingValue={lastMeal.amount}
              lastUpdatedTime={lastMeal.timestamp}
              showLastCarboMeal={true}
              tooltipText="Carbohydrate content of the last communicated meal to the app by the user"
            />
          </Grid>
        </Grid>
        <GraphsWrapper {...basalProfiles} />
      </Box>
    </StyledContainer>
  );
};
