import {DynamicData} from '../../../api';
import React, {useRef, useEffect, useState, useMemo} from 'react';
import {scaleTime, scaleLinear} from '@visx/scale';
import {AxisBottom} from '@visx/axis';
import {Group} from '@visx/group';
import correctionBolusIcon from './assets/correctionBolus.svg';
import carbohydratesIcon from './assets/carbohydrates.svg';
import mealBolusIcon from './assets/mealBolus.svg';
import hypoTreatmentIcon from './assets/hypoTreatment.svg';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {Grid, IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import moment from 'moment';
import {Icon} from '../../../components';

interface DataPoint {
  x: string;
  y: number;
  yAmount: number;
  isUnitU: boolean;
  icon: string;
  label: string;
  loopMode?: string;
}

interface BolusAndTreatmentChartProps {
  boluses: DynamicData['boluses'];
  mealEvents: DynamicData['mealEvents'];
  cgmMeasurements: DynamicData['cgmMeasurements'];
  crosshairTime: number | null;
  selectedDateRange: string;
  setCrosshairTime: (time: number | null) => void;
  minX: number;
  maxX: number;
}

export const BolusAndTreatmentChart = ({
  boluses,
  mealEvents,
  crosshairTime,
  cgmMeasurements,
  setCrosshairTime,
  selectedDateRange,
  minX,
  maxX,
}: BolusAndTreatmentChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const theme = useTheme();
  const [width, setWidth] = useState(1000);
  const [tooltip, setTooltip] = useState<{x: number; y: number; loopMode: string; label: string | undefined}>({
    x: 0,
    y: 0,
    loopMode: '#C1C1C1',
    label: '',
  });
  const [iconTooltip, setIconTooltip] = useState<{
    x: number;
    y: number;
    yAmount?: number;
    xFormatted?: string;
    label?: string;
    isUnitU: boolean;
  }>({
    x: 0,
    y: 0,
    yAmount: 0,
    xFormatted: '',
    label: '',
    isUnitU: false,
  });

  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};
  const minY = 0;
  const maxY = 200;

  const loopModeColors = {
    CLOSED_LOOP: {color: '#77ACA2', label: 'Closed Loop'},
    OPEN_LOOP: {color: '#EA9E8D', label: 'Open Loop'},
    undefined: {color: '#C1C1C1', label: 'Missing Values'},
  };

  // mealEvents
  const carbohydrates: DataPoint[] = mealEvents
    .filter(event => !event.isHypoTreatment)
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return eventTime >= minX && eventTime <= maxX;
    })
    .map(event => ({
      x: event.eventLocalDateTime,
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: carbohydratesIcon,
      label: 'Carbohydrates',
      isUnitU: false,
      loopMode: event.loopMode,
    }));
  const correctionBoluses: DataPoint[] = mealEvents
    .filter(event => event.isHypoTreatment)
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return eventTime >= minX && eventTime <= maxX;
    })
    .map(event => ({
      x: event.eventLocalDateTime,
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: hypoTreatmentIcon,
      label: 'Hypo Treatment',
      isUnitU: false,
      loopMode: event.loopMode,
    }));

  // boluses
  const mealBolus: DataPoint[] = boluses
    .filter(bolus => bolus.mealBolusSafetyNetAmount !== null)
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return eventTime >= minX && eventTime <= maxX;
    })
    .map(bolus => ({
      x: bolus.eventLocalDateTime,
      y: 50,
      yAmount: bolus.mealBolusSafetyNetAmount!,
      icon: mealBolusIcon,
      label: 'Meal Bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));
  const prandialBoluses: DataPoint[] = boluses
    .filter(bolus => bolus.correctionBolusSafetyNetAmount !== null && bolus.correctionBolusSafetyNetAmount !== 0)
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return eventTime >= minX && eventTime <= maxX;
    })
    .map(bolus => ({
      x: bolus.eventLocalDateTime,
      y: 50,
      yAmount: bolus.correctionBolusSafetyNetAmount!,
      icon: correctionBolusIcon,
      label: 'Automatic\nCorrection Bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));
  const manualBoluses: DataPoint[] = boluses
    .filter(bolus => bolus.manualBolusSafetyNetAmount !== null && bolus.manualBolusSafetyNetAmount !== 0)
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return eventTime >= minX && eventTime <= maxX;
    })
    .map(bolus => ({
      x: bolus.eventLocalDateTime,
      y: 50,
      yAmount: bolus.manualBolusSafetyNetAmount!,
      icon: correctionBolusIcon,
      label: 'Manual\nCorrection Bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));

  const dataPoints: DataPoint[] = [
    ...carbohydrates,
    ...correctionBoluses,
    ...mealBolus,
    ...prandialBoluses,
    ...manualBoluses,
  ];
  const {unit} = useAppSelector(unitSelector);

  const cgmDataCombined = useMemo(
    () =>
      cgmMeasurements
        .filter(event => {
          const eventTime = new Date(event.eventLocalDateTime).getTime();
          return eventTime >= minX && eventTime <= maxX;
        })
        .map(cgm => ({
          x: cgm.eventLocalDateTime,
          loopMode: cgm.loopMode,
        })),
    [cgmMeasurements, unit, minX, maxX],
  );

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });
  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  // adjust tick number based on screen width
  const numTicks = useMemo(() => {
    return Math.floor(width / 75);
  }, [width]);

  const handleMouseMove = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    segment?: {startX: number; endX: number; color: string; label: string},
  ) => {
    const {clientX} = event;
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = clientX - svgRect.left;
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();
    setCrosshairTime(timeAtMousePosition);

    if (segment) {
      setTooltip({
        x: mouseXPosition,
        y: -5,
        loopMode: segment.color,
        label: segment.label,
      });
    }
  };

  const handleMouseLeave = () => {
    setCrosshairTime(null);
    setTooltip({x: 0, y: 0, loopMode: '', label: ''}); // loopMode tooltip
    setIconTooltip({x: 0, y: 0, xFormatted: '', label: '', yAmount: 0, isUnitU: false});
  };

  const renderContinuousLoopModeLine = (cgmData: typeof cgmDataCombined, xScale: any) => {
    // helper function grouping CGM data into color segments
    const getColorSegments = (cgmData: typeof cgmDataCombined) => {
      const segments: {startX: number; endX: number; color: string; label: string}[] = [];

      if (cgmData.length === 0) return segments; // return nothing in case there is no data
      // initial segment values starting from ost recent timepoint
      let currentEndX = maxX;
      let currentData = loopModeColors.OPEN_LOOP;
      let currentStartX = currentEndX;
      const fiveMinInMs = 5 * 60 * 1000;
      const tolInMs = 0.5 * 60 * 1000;

      // calculate time from minX and maxX to nearest data point to handle explicitly
      const startGapTime = Math.abs(new Date(cgmData[0].x).getTime() - maxX);
      const endGapTime = Math.abs(new Date(cgmData[cgmData.length - 1].x).getTime() - minX);

      // update start if gap before the first data point with added tolerance (30 seconds)
      if (startGapTime > fiveMinInMs + tolInMs) {
        const gapStartX = new Date(cgmData[0].x).getTime() + fiveMinInMs;
        currentStartX = gapStartX;
      }

      // helper function to push the current segment
      const pushSegment = (startX: number, endX: number, color: string, label: string) => {
        segments.push({
          startX: xScale(startX),
          endX: xScale(endX),
          color,
          label,
        });
      };

      // loop through data to create segments
      for (let i = 0; i < cgmData.length; i++) {
        const cgm = cgmData[i];
        const startX = new Date(cgm.x).getTime(); // calculate current time
        const loopModeData = loopModeColors[cgm.loopMode as keyof typeof loopModeColors]; // check if loop mode has changed

        const timeDifference = Math.abs(startX - currentStartX);
        // push segment if loop mode changes
        if (timeDifference > fiveMinInMs + tolInMs) {
          // update values to add gap segment
          const gapStartX = startX + fiveMinInMs; // define start 5 minutes after earlier data point
          if (currentData != loopModeColors.OPEN_LOOP) {
            pushSegment(currentStartX, currentEndX, currentData.color, currentData.label);
            currentEndX = currentStartX;
            currentData = loopModeColors.OPEN_LOOP;
          }
          currentStartX = gapStartX;
        }
        // if mode changed, push current segment and start new one
        if (loopModeData.color !== currentData.color) {
          pushSegment(currentStartX, currentEndX, currentData.color, currentData.label);
          currentEndX = currentStartX;
          currentData = loopModeData;
        }
        currentStartX = startX; // update for next iteration
        // handle earliest data point explicitly
        if (i === cgmData.length - 1) {
          // push segment in case loop changes for earliest time point
          if (loopModeData.color != currentData.color) {
            pushSegment(currentStartX, currentEndX, currentData.color, currentData.label);
            currentEndX = currentStartX;
            currentData = loopModeData;
            currentStartX = startX;
          }
          // handle gap after the earliest data point
          if (endGapTime > fiveMinInMs + tolInMs) {
            if (loopModeColors.OPEN_LOOP != currentData) {
              pushSegment(currentStartX, currentEndX, currentData.color, currentData.label);
              currentEndX = currentStartX;
              currentData = loopModeColors.OPEN_LOOP;
            }
            currentStartX = minX; // update to earliest time point
          }
          pushSegment(currentStartX, currentEndX, currentData.color, currentData.label); // push last segment for remaining time
        }
      }
      return segments;
    };

    const segments = getColorSegments(cgmData); // get all segments based on loop mode color
    return (
      <g>
        {segments.map((segment, index) => {
          return (
            <line
              key={`loopmode-line-${index}`}
              x1={segment.startX}
              y1={10}
              x2={segment.endX}
              y2={10}
              stroke={segment.color}
              strokeWidth={20}
              onMouseEnter={event => {
                const svgRect = svgRef.current?.getBoundingClientRect();
                if (!svgRect) return;
                setTooltip({
                  x: event.clientX - svgRect.left,
                  y: -5,
                  loopMode: segment.color,
                  label: segment.label,
                });
              }}
              onMouseMove={event => handleMouseMove(event, segment)}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
      </g>
    );
  };

  const isLgDateRange =
    selectedDateRange === 'week' ||
    selectedDateRange === 'two-weeks' ||
    selectedDateRange === 'month' ||
    selectedDateRange === 'all';
  const iconsSize = isLgDateRange ? 25 : 50;
  // const labelsSize = isLgDateRange ? 8 : 11;

  const renderIconsAndLabels = (data: DataPoint[], xZoomScale: any, yZoomScale: any) =>
    data.map((d, i) => {
      // calculate icon position
      const iconXPos = xZoomScale(new Date(d.x)) - iconsSize / 2;
      const iconYPos = yZoomScale(d.y) - iconsSize / 2;
      return (
        <g key={`item-${i}`}>
          <image
            href={d.icon}
            x={iconXPos}
            y={iconYPos}
            width={iconsSize}
            height={iconsSize}
            onMouseEnter={() => {
              const svgRect = svgRef.current?.getBoundingClientRect();
              if (!svgRect) return;
              // force crosshair to x value
              setCrosshairTime(Number(new Date(d.x)));

              // define tooltip position boundaries
              const tooltipXPos = Math.min(Math.max(iconXPos + iconsSize / 2, margin.left), width - margin.right - 115);
              const tooltipYPos = Math.min(Math.max(iconYPos - 60), height - margin.bottom - 1);

              setIconTooltip({
                x: tooltipXPos,
                y: tooltipYPos,
                xFormatted: moment(d.x).format('DD/MM/YY HH:mm'),
                label: d.label,
                yAmount: d.yAmount,
                isUnitU: d.isUnitU,
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
          {/* <text
            x={xZoomScale(new Date(d.x))}
            y={yZoomScale(d.y) + iconsSize / 2 + 10}
            textAnchor="middle"
            fontSize={labelsSize}
            fill="black"
            pointerEvents="none">
            {d.isUnitU ? d.yAmount.toFixed(2) + ' U' : d.yAmount.toFixed(0) + ' g'}
          </text> */}
        </g>
      );
    });

  const crosshairXPosition = crosshairTime ? xScale(new Date(crosshairTime)) : null;

  return (
    <Grid container xs={12} spacing={2} mt={2} marginTop={'15px'}>
      <Grid xs={0.5} display={'flex'} padding={0} justifyContent="flex-end" marginLeft="auto">
        <Tooltip
          title={
            <React.Fragment>
              <Typography variant="h6" gutterBottom>
                Control loop and events
              </Typography>
              <Typography variant="body2">
                The control loop ribbon is pink in open-loop and green in closed-loop. Relevant events are displayed as
                icons: a pizza icon for carbohydrate intake, a black syringe icon for meal insulin boluses, a red
                syringe icon for manual or automatic insulin correction boluses, and a black candy icon for hypoglycemia
                treatments. Details of the events are displayed when hovering over them.
              </Typography>
            </React.Fragment>
          }
          arrow>
          <div style={{position: 'relative', float: 'right', height: '15px'}}>
            <IconButton>
              <Icon icon="info" size={16} color={theme.palette.basic[500]} />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
      <Grid xs={11.5}>
        <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}></Typography>
        <svg ref={svgRef} width="100%" height={height} overflow={'visible'}>
          <Group>
            {/* LoopMode*/}
            {renderContinuousLoopModeLine(cgmDataCombined, xScale)}
            {/* LoopMode Tooltip */}
            {tooltip.loopMode && (
              <text
                x={tooltip.x}
                y={tooltip.y}
                fontSize={12}
                fontWeight="bold"
                fill="black"
                textAnchor="middle"
                pointerEvents="none">
                {tooltip.label}
              </text>
            )}
            {/* Grid */}
            <GridColumns
              scale={xScale}
              top={margin.top}
              height={height - margin.bottom - margin.top}
              stroke="#e0e0e0"
              strokeDasharray="5,5"
              pointerEvents="none"
            />
            <GridRows
              scale={yScale}
              width={width - margin.right - margin.left}
              strokeDasharray="2,2"
              left={margin.left}
            />
            <AxisBottom
              top={height - margin.bottom}
              scale={xScale}
              numTicks={numTicks}
              tickClassName="axis-tick"
              tickFormat={d => {
                const date = d instanceof Date ? d : new Date(d.valueOf());
                return date.toLocaleTimeString('en-EU', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                });
              }}
              tickLabelProps={() => ({
                fill: 'black',
                fontSize: 11,
                textAnchor: 'middle',
              })}
            />
            <rect
              x={margin.left}
              y={margin.top}
              width={width - margin.left - margin.right}
              height={height - margin.top - margin.bottom}
              fill={'transparent'}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              pointerEvents="all"
            />
            {/* Crosshair */}
            {crosshairXPosition !== null && (
              <line
                x1={crosshairXPosition}
                x2={crosshairXPosition}
                y1={margin.top}
                y2={height - margin.bottom}
                stroke="gray"
                strokeDasharray="4,4"
              />
            )}
            {/* Icons and Labels */}
            {renderIconsAndLabels(dataPoints, xScale, yScale)}
            {iconTooltip.xFormatted && iconTooltip.label && (
              <foreignObject x={iconTooltip.x} y={iconTooltip.y} width={200} height={180} pointerEvents="none">
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    justifyContent: 'center',
                    textAlign: 'center',
                    zIndex: 9999,
                    minWidth: '111px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '130px',
                  }}>
                  <h5
                    style={{
                      margin: 0,
                      padding: 0,
                      whiteSpace: 'pre-line',
                      color: theme.palette.customColors?.lightGrey || 'black',
                    }}>
                    {iconTooltip.label}
                  </h5>
                  <p style={{fontWeight: 'bold', fontSize: '14px', margin: 0, padding: 0}}>
                    {(() => {
                      if (iconTooltip.yAmount === undefined) {
                        return 'N/A';
                      }

                      if (iconTooltip.isUnitU) {
                        return `${iconTooltip.yAmount.toFixed(2)} U`;
                      }

                      return `${iconTooltip.yAmount.toFixed(0)} g`;
                    })()}
                  </p>
                  <p style={{fontSize: '12px', margin: 0, padding: 0}}>{iconTooltip.xFormatted}</p>
                </div>
              </foreignObject>
            )}
          </Group>
        </svg>
      </Grid>
    </Grid>
  );
};
