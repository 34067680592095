import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_HTTPS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchEvents = async (queryParams: string) => {
  try {
    const response = await apiClient.get(`/event-notifications?${queryParams}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};
