import {useState, useEffect, useCallback} from 'react';
import {fetchEvents} from '../services/eventsService';
import {EventsResponse} from '../api';

export const useEvents = ({buildQueryParams, dependencies}: {buildQueryParams: () => string; dependencies: any[]}) => {
  const [data, setData] = useState<EventsResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getEvents = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = buildQueryParams();
      const events = await fetchEvents(queryParams);
      setData(events);
    } catch (err: any) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [buildQueryParams]);

  useEffect(() => {
    getEvents();
  }, [...dependencies]);

  return {data, loading, error, refetch: getEvents};
};
