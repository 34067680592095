import {Box, Typography, useTheme} from '@mui/material';
import {useState} from 'react';
import dayjs from 'dayjs';
import {Link, useParams} from 'react-router-dom';

import {EventCard, Icon, MultiSelect, Scrollbar, CircularProgress} from '../../../components';
import {pollingInterval} from '../../../constants';
import {participantsService, eventsService} from '../../../api';
import type {Participant} from '../../../api/service-types';

import {StyledContainer, StyledContent, StyledHeader} from './styles';
import {NoEventsYet} from '../Utils';

export const Events = (): JSX.Element => {
  return (
    <StyledContainer>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
        }}>
        <Content />
      </Scrollbar>
    </StyledContainer>
  );
};

const Content = (): JSX.Element => {
  const theme = useTheme();
  const {id} = useParams();

  const [selectedParticipants, setSelectedParticipants] = useState<Participant[]>([]);

  // const [eventsWS, setEventsWS] = useState<Event[]>([]);

  if (!id) throw new Error('clinical trial id is undefined, unhandled error');

  const {
    data: participantsByClinicalTrialId,
    isLoading: areParticipantsByClinicalTrialIdLoading,
    error: participantsByClinicalTrialIdError,
    refetch: refetchParticipantsByClinicalTrialId,
  } = participantsService.useGetParticipantsByClinicalTrialIdQuery({
    params: {id},
  });

  const {
    data: events,
    isLoading: areEventsLoading,
    error: eventsError,
    refetch: refetchEvents,
  } = eventsService.useGetEventsByClinicalTrialIdQuery({params: {id}}, {pollingInterval});

  const onRefetch = async () => {
    refetchParticipantsByClinicalTrialId();
    refetchEvents();
  };

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areParticipantsByClinicalTrialIdLoading || areEventsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (participantsByClinicalTrialIdError || eventsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!participantsByClinicalTrialId || !events) {
    throw new Error('participantsByClinicalTrialId or events are undefined, unhandled state');
  }

  const eventsByClinicalTrialParticipants = events.data.filter(event =>
    participantsByClinicalTrialId.some(participant => event.participantId === participant.id),
  );

  const selectParticipants = (nextSelectedParticipants: Participant[]) => {
    setSelectedParticipants(nextSelectedParticipants);
  };

  const hasSelectedParticipants = selectedParticipants.length > 0;

  const eventsSorted = [...eventsByClinicalTrialParticipants].sort((a, b) => {
    const eventDateTimeA = dayjs(a.originatingEvent.eventLocalDateTime);
    const eventDateTimeB = dayjs(b.originatingEvent.eventLocalDateTime);

    if (eventDateTimeA.isAfter(eventDateTimeB)) return -1;
    if (eventDateTimeA.isBefore(eventDateTimeB)) return 1;

    return 0;
  });

  const eventsFiltered = hasSelectedParticipants
    ? eventsSorted.filter(
        event =>
          selectedParticipants.some(participant => event.participantId === participant.id) && event.priority === 3,
      )
    : eventsSorted.filter(event => event.priority === 3);

  const hasFoundEvents = eventsFiltered.length > 0;

  /*
  if(hasFoundEvents) {
    setEventsWS(eventsWS.concat(eventsFiltered));
  } 
  */
  return (
    <>
      <StyledContent>
        <StyledHeader>
          <Link to="/dashboard/events">
            <Typography variant="linkHeading">Event</Typography>
          </Link>
          <Icon icon="right" color={theme.palette.basic[600]} size="16px" />
          <Box flex={1} />
          <MultiSelect
            value={selectedParticipants}
            options={participantsByClinicalTrialId}
            onChange={selectParticipants}
            placeholder="Participants"
            width={'142px'}
            searchKeys={['name']}
            keyExtractor={(option: Participant) => String(option.id)}
            renderOption={(option: Participant, isSelected) => (
              <Typography
                variant={isSelected ? 'bodyBigSemibold' : 'bodyBig'}
                color={isSelected ? theme.palette.primaryCustom[1000] : theme.palette.basic[800]}>
                {option.name}
              </Typography>
            )}
          />
        </StyledHeader>
        <div>
          {hasFoundEvents ? (
            eventsFiltered.map(event => <EventCard key={`${event.id}-${event.originatingEvent.type}`} event={event} />)
          ) : (
            <NoEventsYet />
          )}
        </div>
      </StyledContent>
    </>
  );
};
