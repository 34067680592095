import {Box, Typography, IconButton, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';

import {eventsService, StatusFilter} from '../../api';
import {FormattedStatus} from '../../sections/events/events-table/utils';
import {GetStatusBackground} from '../../utils/style';
import {BasicSearchableSelect} from '../select';
import {usePopover} from '../../hooks';
import {SnackbarAlert} from '../snackbar';
import {Severity} from '../event-comments/types';

export interface StatusComponentProps {
  onClose: () => void;
  onRefetch: () => void;
  selectedEvent?: any;
  uniqueStatuses: StatusFilter[];
  setSelectedEvent: React.Dispatch<React.SetStateAction<string>>;
}

export const StatusComponent = ({selectedEvent, uniqueStatuses, onClose, setSelectedEvent}: StatusComponentProps) => {
  const theme = useTheme();
  const {handleClose} = usePopover<HTMLButtonElement>();

  // States
  const [selectedStatus, setSelectedStatus] = useState<StatusFilter | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState<Severity>('success');
  const [message, setMessage] = useState('');
  const [updateEvent] = eventsService.useUpdateEventMutation();

  // Find default selected status
  const defaultStatusOption =
    uniqueStatuses.find(option => option.status?.toLowerCase() === selectedEvent?.status?.toLowerCase()) || null;

  useEffect(() => {
    setSelectedStatus(defaultStatusOption);
  }, [defaultStatusOption]);

  const handleSnackbarClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleUpdateEventStatus = async (nextSelectedStatus: StatusFilter) => {
    const newStatus = nextSelectedStatus?.status?.toLowerCase();
    const currentStatus = selectedStatus?.status?.toLowerCase();

    try {
      await updateEvent({
        body: {
          id: selectedEvent?.id,
          status: currentStatus !== newStatus ? newStatus : 'null',
        },
      });

      // Update states
      setSelectedEvent(() => ({
        ...selectedEvent,
        status: newStatus,
      }));
      setMessage('Event status updated successfully!');
      setSeverity('success');
      setSelectedStatus(currentStatus !== newStatus ? nextSelectedStatus : null);
      setOpenSnackbar(true);
      handleClose();
    } catch (error) {
      setMessage('Error while updating event status!');
      setSeverity('error');
      setOpenSnackbar(true);
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {/* Status Dropdown */}
      <BasicSearchableSelect
        defaultValue={defaultStatusOption}
        value={selectedStatus}
        placeholder="Status"
        options={uniqueStatuses}
        onChange={handleUpdateEventStatus}
        //@ts-expect-error: events todo
        width={108}
        searchKeys={['status']}
        keyExtractor={(option: FormattedStatus) => String(option.id)}
        renderOption={(option: FormattedStatus, isSelected: boolean) => (
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', ml: '-5px'}}>
            <CircleIcon
              sx={{
                height: '8px',
                width: '8px',
                marginRight: '5px',
                color: GetStatusBackground(option.status),
              }}
            />
            <Typography
              variant={isSelected ? 'bodyBigSemibold' : 'bodyBig'}
              color={isSelected ? theme.palette.primaryCustom[1000] : theme.palette.basic[800]}>
              {option.status !== 'null' ? option.status : 'N/A'}
            </Typography>
          </Box>
        )}
      />

      {/* Close Button */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>

      {/* Snackbar Notification */}
      <SnackbarAlert open={openSnackbar} onClose={handleSnackbarClose} message={message} severity={severity} />
    </Box>
  );
};
