import {useTheme, Typography, Tooltip, Box} from '@mui/material';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import {Icon, InsulinTrendIcon, ReservoirIcon} from '../../../components';
import {DeviceCardProps} from './types';

import {
  StyledContainer,
  StyledContent,
  StyledLastUpdate,
  StyledTimeValue,
  StyledValueContainer,
  StyledValue,
  StyledOperationMode,
  StyledIconButton,
} from './styles';
import moment from 'moment-timezone';

export const getLoopColor = (readingMode: string | undefined, theme: any): string => {
  const colors: Record<string, string> = {
    O: '#EA9E8D',
    C: '#77ACA2',
  };

  return colors[readingMode?.charAt(0) ?? ''] ?? theme.palette.primaryCustom[900];
};

export const DeviceCard = ({
  cardHeading,
  showReservoirDetails,
  reservoirPercentage,
  showBatteryPercentage,
  batteryPercentage,
  lastUpdatedTimeTitle,
  lastUpdatedTime,
  showReadingValueAndUnit,
  showLastHoursInsulin,
  showLoopMode,
  readingValue,
  secondReadingValue,
  readingUnit,
  readingTrend,
  readingMode,
  isDeviceCard,
  tooltipText,
  showLastCarboMeal,
}: DeviceCardProps): JSX.Element => {
  const theme = useTheme();

  const timeZone = 'Europe/London'; // TODO: adjust for different participant time zones (second release)
  const dateTimeAgo = lastUpdatedTime && moment().tz(timeZone).diff(lastUpdatedTime, 'minutes') > 6;
  const dateTimestamp =
    lastUpdatedTime && moment().tz(timeZone).isSame(moment(lastUpdatedTime), 'day')
      ? moment(lastUpdatedTime).format('HH:mm')
      : moment(lastUpdatedTime).format('HH:mm (YYYY-MM-DD)');
  const showInsulineTime =
    showLastHoursInsulin && lastUpdatedTime && moment().tz(timeZone).diff(lastUpdatedTime, 'minutes') < 120;
  const insulinTimestamp = showLastHoursInsulin ? moment().tz(timeZone).diff(lastUpdatedTime, 'minutes') : 0;
  let iconColor;
  if (readingValue) {
    iconColor = dateTimeAgo ? 'red' : theme.palette.primaryCustom[800];
  } else {
    iconColor = theme.palette.primaryCustom[900];
  }
  return (
    <StyledContainer sx={{height: '100%', maxWidth: '500px'}}>
      <StyledValueContainer sx={{width: '100%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <StyledLastUpdate>
          <StyledOperationMode sx={{alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'row'}}>
            <Typography variant="body1" sx={{fontWeight: '600', color: `${theme.palette.basic[900]}`}}>
              {cardHeading}
            </Typography>
            <Tooltip title={tooltipText}>
              <StyledIconButton style={{marginTop: '1px'}}>
                <Icon icon="info" size={/*IsMobile() ? 12 :*/ 16} color={theme.palette.basic[500]} />
              </StyledIconButton>
            </Tooltip>
          </StyledOperationMode>
          {showLastCarboMeal && (
            <StyledTimeValue>
              <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                {secondReadingValue ? dateTimestamp : '--'}
              </Typography>
            </StyledTimeValue>
          )}
        </StyledLastUpdate>
        {isDeviceCard && (
          <Box sx={{display: 'flex', flexDirection: 'column', ml: '46px', alignItems: 'center', flexWrap: 'wrap'}}>
            {showReservoirDetails && (
              <StyledOperationMode>
                <Box sx={{width: '20px', height: '20px'}}>
                  <ReservoirIcon />
                </Box>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#535B62',
                    mt: '3.5px',
                    mr: '8px',
                    ml: '2px',
                    alignItems: 'flex-start',
                  }}>
                  {(reservoirPercentage ?? NaN) * 100}% Insulin Reservoir Level
                </Typography>
              </StyledOperationMode>
            )}
            {showBatteryPercentage && (
              <StyledOperationMode>
                <BatteryCharging90Icon sx={{transform: 'rotate(-90deg)'}} />
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#535B62',
                    mt: '3.5px',
                    mr: '8px',
                    ml: '2px',
                    alignItems: 'flex-start',
                  }}>
                  {(batteryPercentage ?? NaN) * 100}% Insulin Pump Battery
                </Typography>
              </StyledOperationMode>
            )}
            {showLoopMode && (
              <StyledOperationMode>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: '700',
                    color: getLoopColor(readingMode, theme),
                    height: '1.8rem',
                    marginRight: '0.5rem',
                    textAlign: 'right',
                  }}>
                  {readingMode?.replace(/_/g, ' ')}
                </Typography>
              </StyledOperationMode>
            )}
            {/* TODO: adding logic
              {!IsMobile() && (
              <Chip
                label="CONNECTED"
                size={'small'}
                style={{backgroundColor: 'rgb(136, 144, 158)', color: '#fafafa'}}
              />
            )} */}
          </Box>
        )}
        {showLastCarboMeal && (
          <StyledValueContainer sx={{flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <StyledValue style={{marginTop: '20px', marginRight: '1rem'}}>
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  fontWeight: '700',
                  color: `${theme.palette.basic[900]}`,
                  height: '1.8rem',
                }}>
                {secondReadingValue ? secondReadingValue : '--'}
              </Typography>
              <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>g</Typography>
            </StyledValue>
          </StyledValueContainer>
        )}
      </StyledValueContainer>
      <StyledContent sx={{height: '100%'}}>
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <StyledLastUpdate sx={{marginTop: 'auto'}}>
            <Typography
              variant="body1"
              fontWeight={showLastCarboMeal ? '600' : '400'}
              color={showLastCarboMeal ? theme.palette.primaryCustom[900] : theme.palette.customColors?.lightGrey}
              marginRight={showLastHoursInsulin ? '1.5rem' : '0'}>
              {lastUpdatedTimeTitle}
            </Typography>
            {!showLastHoursInsulin && (
              <StyledTimeValue>
                {!showLastCarboMeal && (
                  <Tooltip
                    title={
                      <Typography sx={{fontSize: '11px'}}>
                        green: last update {'<'} 5 min
                        <br />
                        red: last update {'>'} 5 min
                      </Typography>
                    }
                    arrow>
                    <StyledIconButton>
                      <Icon icon="check-rounded" size={20} color={iconColor} />
                    </StyledIconButton>
                  </Tooltip>
                )}
                <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                  {readingValue ? dateTimestamp : '--'}
                </Typography>
              </StyledTimeValue>
            )}
            {showInsulineTime && (
              <StyledTimeValue>
                <Typography fontWeight="700" variant="bodyBig" color={'red'}>
                  {readingValue ? 'Infused in the last ' + insulinTimestamp + ' minutes!' : ''}
                </Typography>
              </StyledTimeValue>
            )}
          </StyledLastUpdate>
          {showReadingValueAndUnit && (
            <StyledValueContainer>
              <StyledValue>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue ? readingValue : '--'}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>{readingUnit}</Typography>
                {readingTrend ? <InsulinTrendIcon trend={readingTrend} size={18} /> : '--'}
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastHoursInsulin && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '0.5rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue ? readingValue?.toFixed(2) : '--'}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastCarboMeal && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '1rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue ? readingValue?.toFixed(2) : '--'}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
        </Box>
      </StyledContent>
    </StyledContainer>
  );
};
