import {Navigate, useRoutes} from 'react-router-dom';

import {AuthLayout, DashboardLayout, SimpleLayout} from '../src/layouts';
import {AuthGuard, GuestGuard} from '../src/guards';
import {
  CreateNewPasswordPage,
  ClinicalTrialPage,
  DataTablesPage,
  ForgotPasswordPage,
  LoginPage,
  Page404,
  ParticipantsPage,
  ParticipantPage,
  SettingsPage,
  EventsPage,
  LogsPage,
} from '../src/pages';

export const Router = () => {
  const routes = useRoutes([
    {
      path: '/auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {element: <Navigate to="/auth/login" />, index: true},
        {path: 'login', element: <LoginPage />},
        {path: 'forgot', element: <ForgotPasswordPage />},
      ],
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {element: <Navigate to="/auth/create-new-password" />, index: true},
        {path: 'create-new-password/', element: <CreateNewPasswordPage />},
        {path: 'create-new-password/:id', element: <CreateNewPasswordPage />},
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {element: <Navigate to="/dashboard/1" />, index: true},
        {path: ':id', element: <ClinicalTrialPage />},
        {path: 'participants', element: <ParticipantsPage />},
        {path: 'participants/:id', element: <ParticipantPage />},
        {path: 'data-tables', element: <DataTablesPage />},
        {path: 'settings', element: <SettingsPage />},
        {path: 'events', element: <EventsPage />},
        {path: 'logs', element: <LogsPage />},
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        {element: <Navigate to="/auth" />, index: true},
        {path: '404', element: <Page404 />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
};
